<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modal_sale_low_detail" class="w-100" title="Resumen de Venta - Detallea">
      <b-row>
        <b-col md="2">
          <b-form-group label="Documento:">
            <b-form-input class="text-center" disabled type="text" :value="sale_rc.code+' '+sale_rc.serie+'-'+sale_rc.sequence"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Fecha Doc.:">
            <b-form-input class="text-center" disabled type="date" v-model="sale_rc.low_date"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Fecha Ref.:">
            <b-form-input class="text-center" disabled type="date" v-model="sale_rc.reference_date"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Acción:">
            <b-form-select disabled v-model="sale_rc.state_sale" :options="state" ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Motivo:">
            <b-form-input disabled type="text" v-model="sale_rc.reason"></b-form-input>
          </b-form-group>
        </b-col>
        
        <b-col md="12">
           <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Comprobante</th>
                    <th width="40%" class="text-center">Cliente</th>
                    <th width="5%" class="text-center">Moneda</th>
                    <th width="5%" class="text-center">Total</th>
                    <th width="8%" class="text-center">Estado</th>
                  
                  </tr>
                </thead>
                <tbody v-for="(item, it) in date_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ item.document }}</td>
                    <td class="text-left"> {{ item.client }}</td>
                    <td class="text-center"> {{ item.coin }} </td>
                    <td class="text-right"> {{ item.total }} </td>
                    <td class="text-center"> 
                      <b-badge v-if="item.state == 3" variant="info">Emitido</b-badge>
                      <b-badge v-if="item.state == 4" variant="success">Aceptado</b-badge>
                      <b-badge v-if="item.state == 6" variant="danger">Anulado</b-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>



</style>


<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";



export default {
  name: "ModalsProduct",
  data() {
    return {
        modal_sale_low_detail:false,
        module:'SaleSummary',
        role:2,
        state:[
          {value:1, text:'Enviar por RA'},
          {value:3, text:'Anular por RA'},
        ],
        origin:[
          {value:'Sale', text:'Venta'},
          {value:'RedeemedSale', text:'Canje de Venta'},
        ],
        check_all: 1,
        id_sale_low :0,
        sale_rc :{
          id_sale_low:'',
          origin:'Sale',
          id_sale:'',
          id_user:'',
          code:'',
          serie:'',
          sequence:'',
          reference_date:moment(new Date()).local().format("YYYY-MM-DD"),
          low_date:'',
          reason:'',
          sunat_message:'',
          state_sale:3,
          state:1,
          detail:[],
        },
        validate: false,
        errors:{
          reference_date:false,
          reason:false,
          detail:false,
        },
        search_order:'',
        date_table: [],
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalRADetailShow', (id_sale_low) => {
      this.modal_sale_low_detail = true;
      this.id_sale_low = id_sale_low;
      this.ListSaleRA();
    });
    
  },
  methods: {
      ListSaleRA,
      ChangeCheckAll,
      AddSaleSummary,
      CodeInvoice,
      Validate,
      ...mapActions('Sale',['mLoadAddSaleDetail','mLoadAddLinkages']),
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function ChangeCheckAll() {
  let me = this;
  if (me.check_all == 1) {
    for (let index = 0; index < me.date_table.length; index++) {
      me.date_table[index].check = 1;
    }
  }else{
    for (let index = 0; index < me.date_table.length; index++) {
      me.date_table[index].check = 0;
    }
  }
}



function AddSaleSummary() {

    let me = this;
    let url = this.url_base + "sale-summary/add";

    this.sale_rc.id_establishment = me.id_establishment;
    this.sale_rc.id_user = me.user.id_user;
    let data = this.sale_rc;

    axios({
      method: "POST",
      url: url,
      data: data,
      headers: { token: this.token, module: this.module,role: this.role},
    })
    .then(function (response) {
      if (response.data.status == 201) {
        me.modal_sale_low_detail = false;
        EventBus.$emit('RefreshListSalesRC');
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

   
}
//Buscar productos
function ListSaleRA() {
  let me = this;
  let url = this.url_base + "sale-summary/view/"+this.id_sale_low;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
      if (response.data.status == 200) {
        me.sale_rc.code = response.data.result.sale_low.code;
        me.sale_rc.serie = response.data.result.sale_low.serie;
        me.sale_rc.sequence = response.data.result.sale_low.sequence;
        me.sale_rc.reference_date = response.data.result.sale_low.reference_date;
        me.sale_rc.low_date = response.data.result.sale_low.low_date;
        me.sale_rc.reason = response.data.result.sale_low.reason;

        me.date_table = response.data.result.sale_low_detail;
      }
  })
}


function Validate() {
  this.errors.reference_date = this.sale_rc.reference_date.length == 0 ? true : false;
  this.sale_rc.detail = [];
  for (let index = 0; index < this.date_table.length; index++) {
    const element = this.date_table[index];
    if (element.check == 1) {
      this.sale_rc.detail.push(element);
    }
  }
  this.errors.detail = this.sale_rc.detail.length == 0 ? true : false;

  if (this.errors.reference_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Seleccione 1 o mas comprobantes', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;
  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de guardar el resumen de venta?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddSaleSummary();
      }
    });

  }
}
</script>
