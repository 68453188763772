<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modal_ra" class="w-100" title="Resumen de Ventas - FACTURAS">
      <b-row>
        <b-col md="2">
          <b-form-group label="Origen:">
            <b-form-select :options="origin" v-model="sale_ra.origin" @change="SearchSaleRC"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Fecha:">
            <b-form-input class="text-center" type="date" v-model="sale_ra.reference_date" @change="SearchSaleRC"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Acción:">
            <b-form-select v-model="sale_ra.state_sale" :options="state" @change="SearchSaleRC"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Motivo:">
            <b-form-input type="text" v-model="sale_ra.reason"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label=".">
            <b-button @click="Validate" class="form-control" type="button" variant="primary"><i class="fas fa-save"></i> Guardar</b-button>
          </b-form-group>
        </b-col>
        <b-col md="12">
           <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="10%" class="text-center">Comprobante</th>
                    <th width="40%" class="text-center">Cliente</th>
                    <th width="5%" class="text-center">Moneda</th>
                    <th width="5%" class="text-center">Total</th>
                    <th width="8%" class="text-center">Estado</th>
                    <th width="6%" class="text-center">
                      <b-form-checkbox @change="ChangeCheckAll" v-model="check_all" value="1" unchecked-value="0">  
                      </b-form-checkbox>
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in date_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.broadcast_date }}</td>
                    <td class="text-center"> {{ CodeInvoice(item.type_invoice) + " " + item.serie + "-"+item.number }}</td>
                    <td class="text-left"> {{ item.client_name + ' - '+item.client_document_number }}</td>
                    <td class="text-center"> {{ item.coin }} </td>
                    <td class="text-right"> {{ item.total }} </td>
                    <td class="text-right"> 
                      <b-badge v-if="item.state == 3" variant="info">Emitido</b-badge>
                      <b-badge v-if="item.state == 4" variant="success">Aceptado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox v-model="item.check" value="1" unchecked-value="0">  
                      </b-form-checkbox>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>



</style>


<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";



export default {
  name: "ModalsProduct",
  data() {
    return {
        modal_ra:false,
        module:'SaleSummary',
        role:2,
        state:[
          {value:3, text:'Enviar por RC'},
          {value:4, text:'Anular por RC'},
        ],
        origin:[
          {value:'Sale', text:'Venta'},
          {value:'RedeemedSale', text:'Canje de Venta'},
        ],
        check_all: 1,
        sale_ra :{
          id_sale_low:'',
          origin:'Sale',
          id_sale:'',
          id_user:'',
          code:'',
          serie:'',
          sequence:'',
          reference_date:moment(new Date()).local().format("YYYY-MM-DD"),
          low_date:'',
          reason:'',
          sunat_message:'',
          state_sale:3,
          state:1,
          detail:[],
        },
        validate: false,
        errors:{
          reference_date:false,
          reason:false,
          detail:false,
        },
        search_order:'',
        date_table: [],
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalRAShow', () => {
      this.modal_ra = true;
      this.SearchSaleRC();
      this.check_all = 1;
    });
    
  },
  methods: {
      SearchSaleRC,
      ChangeCheckAll,
      AddSaleSummary,
      CodeInvoice,
      Validate,
      ...mapActions('Sale',['mLoadAddSaleDetail','mLoadAddLinkages']),
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    }
  },
};

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function ChangeCheckAll() {
  let me = this;
  if (me.check_all == 1) {
    for (let index = 0; index < me.date_table.length; index++) {
      me.date_table[index].check = 1;
    }
  }else{
    for (let index = 0; index < me.date_table.length; index++) {
      me.date_table[index].check = 0;
    }
  }
}



function AddSaleSummary() {

    let me = this;
    let url = this.url_base + "sale-summary/add";

    this.sale_ra.id_establishment = me.id_establishment;
    this.sale_ra.id_user = me.user.id_user;
    let data = this.sale_rc;

    axios({
      method: "POST",
      url: url,
      data: data,
      headers: { token: this.token, module: this.module,role: this.role},
    })
    .then(function (response) {
      if (response.data.status == 201) {
        me.modal_ra = false;
        EventBus.$emit('RefreshListSalesRC');
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

   
}
//Buscar productos
function SearchSaleRC() {
  let me = this;

  if (me.sale_ra.reference_date.length == 0) {
    return false;
  }
  
  let url = this.url_base + "sale-summary/list-sale-rc";
  let data = {
    id_establishment : me.id_establishment,
    date: me.sale_ra.reference_date,
    state: me.sale_ra.state_sale,
    origin: me.sale_ra.origin,
  }
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { token: this.token,module: this.module,role: this.role},
  })
  .then(function (response) {
      if (response.data.status == 200) {
        me.date_table = response.data.result;
      }
  })
}


function Validate() {
  this.errors.reference_date = this.sale_ra.reference_date.length == 0 ? true : false;
  this.sale_ra.detail = [];
  for (let index = 0; index < this.date_table.length; index++) {
    const element = this.date_table[index];
    if (element.check == 1) {
      this.sale_ra.detail.push(element);
    }
  }
  this.errors.detail = this.sale_ra.detail.length == 0 ? true : false;

  if (this.errors.reference_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Seleccione 1 o mas comprobantes', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;
  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de guardar el resumen de venta?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.AddSaleSummary();
      }
    });

  }
}
</script>
